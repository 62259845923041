import React, { useState } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Contact } from "./components/contact";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import {PageSelector} from "./components/PageSelector";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState(undefined);
  const [isSegmi, setIsSegmi] = useState(false);

  const setPageData = (props) => {
      setLandingPageData(props)
  }

  return (
    <div >
        {!landingPageData ?
            <PageSelector setPageData={setPageData} setIsSegmi={setIsSegmi}/> :
            <>
                <Navigation data={landingPageData.Header} setPageData={setPageData} setIsSegmi={setIsSegmi} isSegmi={isSegmi}/>
                <Header data={landingPageData.Header} isSegmi={isSegmi}/>
                <About data={landingPageData.About} isSegmi={isSegmi}/>
                <Services data={landingPageData.Services} isSegmi={isSegmi}/>
                <Gallery data={landingPageData.Gallery} isSegmi={isSegmi}/>
                <Testimonials data={landingPageData.Testimonials} isSegmi={isSegmi}/>
                <Contact data={landingPageData.Contact} isSegmi={isSegmi}/>
            </>
        }
    </div>
  );
};

export default App;
