import JsonSegundamilla from "../data/data-segundamilla.json";
import JsonSegmi from "../data/data-segmi.json";

export const PageSelector = (props) => {
    return (
        <div className={"page-selector"}>
            <div className={"selector-container"}>
                <div
                    className={"segundamilla selector"}
                    onClick={() => {
                        props.setPageData(JsonSegundamilla)
                        props.setIsSegmi(false);
                    }}
                >
                    <div className={"overlay"}/>
                </div>
                <div className="text">
                    <h1>
                    Segunda milla
                    </h1>
                </div>
            </div>
            <div className={"selector-container"}>
                <div
                    className={"segmi selector"}
                    onClick={() => {
                        props.setPageData(JsonSegmi)
                        props.setIsSegmi(true)
                    }}
                >
                    <div className={"overlay"}/>
                </div>
                <div className="text">
                    <h1>
                        Segmi
                    </h1>
                </div>
            </div>
        </div>
    )
}
