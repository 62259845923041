import React from "react";
import {Build, Cut, Factory, Maintenance, Plane} from "../utils/Icons";

export const Services = (props) => {
  return (
    <div id="services" className={`text-center ${props.isSegmi && "services-segmi"}`}>
      <div className="container">
        <div className="section-title">
          <h2>Servicios</h2>
        </div>
        <div className="service-items"
             style={{
                 margin: "auto",
                 display: "flex",
                 flexFlow: "row wrap",
                 justifyContent: "center",
                 alignItems: "flex-start"

             }}>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="service-item">
                  {" "}
                  <i className={`fa fa-industry ${props.isSegmi && "segmi"}`} aria-hidden="true">
                      <img src={d.icon} alt=""/>
                  </i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
