import React from "react";
import JsonSegundamilla from "../data/data-segundamilla.json";
import JsonSegmi from "../data/data-segmi.json";
import {Image} from "./image";

export const Navigation = (props) => {
  return (
    <nav id="menu" className={`navbar navbar-default navbar-fixed-top ${props.isSegmi && "segmi"}`}>
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          {/*<a className="navbar-brand page-scroll" href="#page-top">*/}
              {props.isSegmi ? (
                  <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0px 20px 0",
                  }}>
                      <img style={{maxWidth: "200px",
                          maxHeight: "55px"}}src={"img/SEGMI.png"} className="img-responsive" alt={"2milla"}/>
                  </div>
              ) : (
                  <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "16px 20px 0"
                  }}>
                      <img style={{maxWidth: "200px",
                          maxHeight: "45px"}} src={"img/logo-2milla.png"} className="img-responsive" alt={"2milla"}/>
                  </div>
              )}
          {/*</a>{" "}*/}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className={`nav navbar-nav ${props.isSegmi && "segmi"} navbar-right`}>
            <li>
              <a href="#about" className="page-scroll">
                Sobre nosotros
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                Servicios
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll">
                Galería
              </a>
            </li>
            <li>
              <a href="#clients" className="page-scroll">
                Clientes
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contacto
              </a>
            </li>
              <li>
                  {props.isSegmi ? (
                      <div
                          onClick={() => {
                              props.setPageData(JsonSegundamilla)
                              props.setIsSegmi(false)
                          }}
                          style={{
                          display: "flex",
                          justifyContent: "center",
                           alignItems: "center",
                          margin: "14px 20px 0"
                      }}>
                        <img src={"img/logo-2milla.png"} className="img-responsive" alt={"2milla"}/>
                      </div>
                  ) : (
                      <div
                          onClick={() => {
                              props.setPageData(JsonSegmi)
                              props.setIsSegmi(true)
                          }}
                          style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "4px 20px 0",
                          maxWidth: "200px",
                          maxHeight: "40px"
                      }}>
                          <img style={{maxWidth: "200px",
                              maxHeight: "45px"}} src={"img/logo-segmi.png"} className="img-responsive" alt={"2milla"}/>
                      </div>
                  )}
              </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
